import React from 'react';
import Eureka from 'eureka'
import ReactDOM from 'react-dom'

import ReactFragmentSample1 from './ReactFragmentSample1'
import ReactFragmentSample2 from './ReactFragmentSample2'

Eureka.FragmentManager.registerFragment(process.env.REACT_APP_NAME + 'ReactFragmentSample1', {
  render (container, props, history) {
    let dom = typeof container === 'string' ? document.getElementById(container) : container
    ReactDOM.render(<ReactFragmentSample1 history={history} {...(props || {})} />, dom)
  },
  unmount (container) {
    let dom = typeof container === 'string' ? document.getElementById(container) : container
    ReactDOM.unmountComponentAtNode(dom)
  },
  component: ReactFragmentSample1,
})

Eureka.FragmentManager.registerFragment(process.env.REACT_APP_NAME + 'ReactFragmentSample2', {
  render (container, props, history) {
    let dom = typeof container === 'string' ? document.getElementById(container) : container
    ReactDOM.render(<ReactFragmentSample2 history={history} {...(props || {})} />, dom)
  },
  unmount (container) {
    let dom = typeof container === 'string' ? document.getElementById(container) : container
    ReactDOM.unmountComponentAtNode(dom)
  },
  component: ReactFragmentSample2
})