import React from 'react';
import { Button, FormSet, FormLabel, FormMessage, FormItem, FormInput } from 'fundamental-react';
export default props => {
  return (
    <div>
      <header>
        <span>React Fragment Sample2</span>
        <Button>Edit</Button>
      </header>
      <section>
      </section>
    </div>
  );
};
